<template>
  <nav class="nav">
    <router-link class="nav__link" to="/list"><span>List</span></router-link>
    <router-link class="nav__link" to="/finished"
      ><span>Finished</span></router-link
    >
    <router-link class="nav__link" to="/discover"
      ><span>Discover</span></router-link
    >
  </nav>
</template>

<script setup lang="ts"></script>

<style scoped></style>
