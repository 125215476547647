
import { defineComponent } from "vue";
import LoginForm from "@/components/LoginForm.vue";
import AppModal from "@/components/AppModal.vue";
import { useAuthActor } from "@/machines/authMachine";
import type { form } from "@/types";

export default defineComponent({
  name: "Home",
  setup() {
    const { sendAuth } = useAuthActor();
    function clearMessage() {
      sendAuth("CLEAR");
    }
    function login(form: form) {
      sendAuth({ type: "LOGIN", form });
    }
    function register(form: form) {
      sendAuth({ type: "SIGNUP", form });
    }

    return {
      login,
      register,
      clearMessage,
    };
  },
  components: {
    LoginForm,
    AppModal,
  },
});
