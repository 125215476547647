
import { defineComponent, PropType, ref } from "vue";

export default defineComponent({
  props: {
    closeAction: { type: Function as PropType<() => void> },
  },
  setup() {
    const open = ref(false);

    function openModal() {
      open.value = true;
    }

    function closeModal() {
      open.value = false;
    }

    return { open, openModal, closeModal };
  },
});
