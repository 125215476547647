
import { computed, defineComponent, PropType, reactive, toRefs } from "vue";
import type { user, form } from "@/types";
import { ErrorMessage } from "@/components/lib";
import { useAuthActor } from "@/machines/authMachine";

export default defineComponent({
  props: {
    submitButtonText: {
      type: String,
      required: true,
    },
    handleSubmit: {
      type: Function as PropType<(form: form) => Promise<user>>,
      required: true,
    },
  },
  setup(prop) {
    const state = reactive({ username: "", password: "" });
    const { authState } = useAuthActor();
    const isFetching = computed(() => authState.value.matches("loading"));
    const hasMessage = computed(() => Boolean(authState.value.context.message));
    const message = computed(() => authState.value.context.message);
    function submitForm() {
      prop.handleSubmit({
        username: state.username,
        password: state.password,
      });
    }

    return { ...toRefs(state), isFetching, hasMessage, message, submitForm };
  },
  components: {
    ErrorMessage,
  },
});
