
import { defineComponent, computed } from "vue";
import AuthenticatedApp from "./Authenticated-app.vue";
import UnauthenticatedApp from "./Unauthenticated-app.vue";
import { useAuthActor, setupAuthService } from "@/machines/authMachine";

export default defineComponent({
  setup() {
    setupAuthService();
    const { authState } = useAuthActor();
    const isLoggedIn = computed<boolean>(() =>
      authState.value.matches("authorized")
    );

    return { isLoggedIn };
  },
  components: {
    AuthenticatedApp,
    UnauthenticatedApp,
  },
});
