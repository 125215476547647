
import { defineComponent } from "vue";
import { useAuthActor } from "@/machines/authMachine";

export default defineComponent({
  setup() {
    const { authState, sendAuth } = useAuthActor();
    function logout() {
      sendAuth({ type: "LOGOUT" });
    }

    return { user: authState.value.context.user, logout };
  },
});
